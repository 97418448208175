import React from 'react';

import { DangerouslySetInnerHtml } from 'layout';
import Image from 'common/Image';

import './FormatBanner.scss';

const FormatBanner = ({ mainTitle, formatItems }: PageContent.FormatBannerType) => (
  <div className="format-banner__wrapper" data-testid="format-banner">
    <p className="format-banner__main-title">{mainTitle}</p>
    <div className="format-banner__items">
      {formatItems.map(({ title, description, image }) => (
        <div className="format-banner__item" key={title}>
          <Image imageData={image} alt={image.altText} className="format-banner__item-image" />
          <div className="format-banner__item-content">
            <p className="format-banner__item-title">{title}</p>
            <DangerouslySetInnerHtml
              html={description}
              className="format-banner__item-description"
            />
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default FormatBanner;
