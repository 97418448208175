import React from 'react';
import { navigate } from 'gatsby';

import { DangerouslySetInnerHtml } from 'layout';
import CustomButton from 'common/CustomButton';
import Image from 'common/Image';

import './ProductBanner.scss';

const ProductBanner = ({
  categoryTitle,
  title,
  description,
  image,
  customButton,
}: PageContent.ProductBannerType) => {
  const { ariaLabel, buttonLabel, buttonLink, backgroundColor } = customButton || {};

  return (
    <div className="product-banner__wrapper" data-testid="product-banner">
      {categoryTitle ? <p className="product-banner__main-title">{categoryTitle}</p> : null}
      <div className="product-banner">
        <Image imageData={image} alt={image.altText} />
        <div className="product-banner__content">
          <p className="product-banner__title">{title}</p>
          <DangerouslySetInnerHtml html={description} className="product-banner__description" />
          <CustomButton
            {...{ ariaLabel, buttonLabel, buttonLink, backgroundColor }}
            className="product-banner__btn"
            onClick={() => navigate(buttonLink?.url!)}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductBanner;
