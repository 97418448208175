import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Banner from 'components/Banner';
import FeatureBanner from 'components/FeatureBanner';
import FormatBanner from 'components/FormatBanner';
import ProductBanner from 'components/ProductBanner';
import ProductsListing from 'components/ProductsListing';
import RecommendedProducts from 'components/RecommendedProducts';
import { PRODUCT_CATEGORY_ITEMS } from 'utils/constants';
import { sortProductsByNodePicker, sortRecommendedProducts } from 'utils/productsSorting';

import './ProductCategory.scss';

const ProductCategory = ({
  data: {
    productCategory: {
      urls,
      seo,
      oneProductTemplate,
      multipleProductsTemplate,
      sortByPlaceholder,
      sortByLabel,
      productsFoundLabel,
      customButton,
      videoBanner,
      productsOrder,
    },
    productLanding: { productsFilters },
    allProductDetails: { nodes: productsMatrix },
    allRecommendedProducts: { nodes: allRecommendedProductsNodes },
  },
  pageContext: {
    breadcrumb: { crumbs },
    lang,
  },
}: ProductCategoryTypes.ProductCategoryProps) => {
  const { title, keywords, description } = seo;
  const crumbsSettings = {
    crumbs,
    isDisplayedCrumbs: true,
  };
  const isOneProduct = productsMatrix.length === 1;
  const template = isOneProduct ? oneProductTemplate : multipleProductsTemplate;

  return (
    <Layout {...{ crumbsSettings }} headerTransparent className="product-category">
      <Seo {...{ lang, urls, seo }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      {template?.items.map(
        (item, index) =>
          ({
            /* eslint-disable react/no-array-index-key */
            [PRODUCT_CATEGORY_ITEMS.banner]: <Banner key={index} {...item[item.alias]} />,
            [PRODUCT_CATEGORY_ITEMS.feature]: <FeatureBanner key={index} {...item[item.alias]} />,
            [PRODUCT_CATEGORY_ITEMS.product]: <ProductBanner key={index} {...item[item.alias]} />,
            [PRODUCT_CATEGORY_ITEMS.recommended]: item.recommendedProducts?.products ? (
              <RecommendedProducts
                key={index}
                {...item[item.alias]}
                {...{
                  allRecommendedProductsNodes: sortRecommendedProducts(
                    item.recommendedProducts?.products,
                    allRecommendedProductsNodes
                  ),
                }}
              />
            ) : null,
            [PRODUCT_CATEGORY_ITEMS.format]: <FormatBanner key={index} {...item[item.alias]} />,
            /* eslint-disable react/no-array-index-key */
          }[item.alias])
      )}
      {!isOneProduct ? (
        <ProductsListing
          {...{
            sortByPlaceholder,
            sortByLabel,
            products: sortProductsByNodePicker(
              productsOrder,
              productsMatrix
            ) as PageContent.ProductType[],
            customButton,
            productsFoundLabel,
            productsFilters,
            isProductCategory: true,
          }}
        />
      ) : null}
      {videoBanner ? <Banner {...videoBanner} /> : null}
    </Layout>
  );
};

export const query = graphql`
  query ($lang: String, $id: String, $recommendedProductsSku: [String], $category: [String]) {
    productCategory(lang: { eq: $lang }, id: { eq: $id }) {
      productsOrder {
        name
      }
      url
      urls {
        lang
        href
      }
      seo {
        ...SEOStructureFragment
      }
      oneProductTemplate {
        ...ProductCategoryType
      }
      multipleProductsTemplate {
        ...ProductCategoryType
      }
      sortByPlaceholder
      sortByLabel
      productsFoundLabel
      customButton {
        ...CustomButtonType
      }
      videoBanner {
        ...BannerType
      }
    }
    productLanding(lang: { eq: $lang }) {
      productsFilters {
        ...ProductsFiltersType
      }
    }
    allProductDetails(filter: { filterByMessType: { in: $category } }) {
      ...ProductDetailsType
    }
    allRecommendedProducts: allProductDetails(filter: { sku: { in: $recommendedProductsSku } }) {
      nodes {
        title
        url
        productImage {
          ...SalsifyImageType
        }
        sku
      }
    }
  }
`;

export default ProductCategory;
